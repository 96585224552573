<mat-toolbar *ngIf="data.mobileType === 'android'" class="android-prompt">
  <button class="storm-button mx-auto" (click)="installPwa()">
    Aggiungi alla home
  </button>
  <mat-icon (click)="close()" class="close-icon cursor-pointer">close</mat-icon>
</mat-toolbar>

<div *ngIf="data.mobileType === 'ios'" class="ios-prompt">
  <mat-icon class="close-icon cursor-pointer" (click)="close()">close</mat-icon>
  <div>Per installare la web app sul tuo dispositivo premere il tasto <b>"Condividi"</b> e poi tasto <b>"Aggiungi alla schermata Home"</b></div>
  <div class="d-flex align-items-center">
    <img class="ios-icon" src="assets/images/ios-share-icon.png">
    <mat-icon>navigate_next</mat-icon>
    <img src="assets/images/ios-add-icon.png" class="ios-icon">
  </div>
</div>
