import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WishlistAuthService} from '../../../../../modules/wishlist/services/wishlist-auth.service';

@Component({
  selector: 'app-lost-password-screen',
  templateUrl: './lost-password-screen.component.html',
  styleUrls: ['./lost-password-screen.component.scss']
})
export class LostPasswordScreenComponent implements OnInit {
  @Input() username: string;

  @Output() lostPasswordPress: EventEmitter<string> = new EventEmitter<string>();

  isLoading: boolean;

  isSubmittedForm = false;
  mailSended = false;
  mailNotSended = false;

  lostPassword: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private wishlistService: WishlistAuthService
  ) {}

  ngOnInit(): void {
    this.lostPassword = this.formBuilder.group({
      email: [this.username, Validators.required],
    });
  }

  sendPassword() {
    this.isLoading = true;

    this.mailSended = false;
    this.mailNotSended = false;
    this.isSubmittedForm = true;

    if (this.lostPassword.invalid){
      this.isLoading = false;
      return;
    }

    this.wishlistService.resetPassword(this.lostPassword.value).subscribe(resp => {
      if (resp){
        this.mailSended = true;
      }
      this.isLoading = false;
    }, (err) => {
      this.mailNotSended = true;
      this.isLoading = false;
    });
  }

  returnLogin(){
    this.mailSended = false;
    this.mailNotSended = false;
    this.lostPasswordPress.emit('returnLogin');
  }

}
