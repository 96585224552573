import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MobileFilterComponent } from './mobile-filter/mobile-filter.component';
import { AgencyListComponent } from './agency-list/agency-list.component';
import {NavigationLinkComponent} from './navigation-link/navigation-link.component';
import {AuthenticationComponent} from './authentication/authentication.component';
import {LoginScreenComponent} from './authentication/components/login-screen/login-screen.component';
import {RegistrationScreenComponent} from './authentication/components/registration-screen/registration-screen.component';
import {LostPasswordScreenComponent} from './authentication/components/lost-password-screen/lost-password-screen.component';
import {ConfirmEmailScreenComponent} from './authentication/components/confirm-email-screen/confirm-email-screen.component';
import {PwaPromptComponent} from './pwa-prompt/pwa-prompt.component';
import {WishlistCartComponent} from './wishlist-cart/wishlist-cart.component';

export const components: any[] = [
  ConfirmationDialogComponent,
  MobileFilterComponent,
  AgencyListComponent,
  NavigationLinkComponent,
  AuthenticationComponent,
  LoginScreenComponent,
  RegistrationScreenComponent,
  ConfirmEmailScreenComponent,
  LostPasswordScreenComponent,
  PwaPromptComponent,
  WishlistCartComponent
];

export * from './confirmation-dialog/confirmation-dialog.component';
export * from './mobile-filter/mobile-filter.component';
export * from './agency-list/agency-list.component';
export * from './navigation-link/navigation-link.component';
export * from './authentication/authentication.component';
export * from './authentication/components/login-screen/login-screen.component';
export * from './authentication/components/registration-screen/registration-screen.component';
export * from './authentication/components/lost-password-screen/lost-password-screen.component';
export * from './authentication/components/confirm-email-screen/confirm-email-screen.component';
export * from './pwa-prompt/pwa-prompt.component';
export * from './wishlist-cart/wishlist-cart.component';
