import { Injectable } from '@angular/core';
import { BleenSingleChoiceComponent, BleenMultiChoiceComponent } from '@bleen/components';
import { CircleFilterWidgetComponent } from '@shared/components/circle-filter-widget/circle-filter-widget.component';

export const ComponentMapping = {
  SINGLE_CHOICE: 'single_choice',
  MULTI_CHOICE: 'multi_choice',
  CIRCLES_WIDGET: 'circle_filters'
};

@Injectable({
  providedIn: 'root'
})
export class ComponentMappingService {

  constructor() { }

  getComponentClass(componentType: string) {
    switch (componentType) {
      case ComponentMapping.SINGLE_CHOICE:
        return BleenSingleChoiceComponent;
      case ComponentMapping.MULTI_CHOICE:
        return BleenMultiChoiceComponent;
      case ComponentMapping.CIRCLES_WIDGET:
        return CircleFilterWidgetComponent;
      default:
        return null;
    }
  }
}
