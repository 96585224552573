import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {WishlistAuthService} from '../modules/wishlist/services/wishlist-auth.service';

@Injectable()
export class AuthGuard implements CanActivate {


  constructor(
    private wishlistAuthService: WishlistAuthService,
    private _router: Router
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.wishlistAuthService.isUserLogged) {
      return true;
    }

    // navigate to login page
    this._router.navigate(['/']);
    // you can save redirect url so after authing we can move them back to the page they requested
    return false;
  }

}
