import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {WishlistAuthService} from '../../modules/wishlist/services/wishlist-auth.service';
import {Router} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private wishlistAuthService: WishlistAuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipIntercept = request.headers.has('Skip-interceptor');
    if (skipIntercept) {
      const cleanReq = request.clone({
        headers: request.headers.delete('Skip-interceptor')
      });
      return next.handle(cleanReq);
    } else {
      const jwtToken = localStorage.getItem('tokenAuthorization');
      if (jwtToken) {
        const authReq = request.clone({
          headers: request.headers.set('Authorization', jwtToken),
        });
        return next.handle(authReq).pipe(catchError(x => this.handleAuthError(x)));
      } else {
        return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
      }
    }
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      this.wishlistAuthService.logout();
      this.router.navigateByUrl(`/results`);
    }
    return throwError(err);
  }
}
