<ngx-loading [show]="isLoading === true"></ngx-loading>
<div class="lost-password-box overflow-auto">
  <form
    class="h-100 d-flex flex-column justify-content-between"
    [ngClass]="isSubmittedForm ? 'sumbitted' : ''"
    [formGroup]="lostPassword">
    <div class="lost-password-form text-center pb-4">
      <b class="mb-5">Inserisci la tua email</b>
      <input
        class="w-100 text-center form-control"
        type="email"
        placeholder="Email"
        formControlName="email"
      />
      <div class="mt-2 p-2 w-100 text-center alert alert-success" *ngIf="mailSended">La mail è stata inviata con successo.</div>
      <div class="mt-2 p-2 w-100 text-center alert alert-danger" *ngIf="mailNotSended">C'è stato un errore durante l'invio della mail, riprovare.</div>
    </div>
    <div>
      <div class="col-12 mb-2" (click)="sendPassword()">
        <div class="storm-button storm_primary">Reimposta Password</div>
      </div>
      <div class="col-12" (click)="returnLogin()">
        <div class="storm-button storm_primary">Torna alla Login</div>
      </div>
    </div>
  </form>
</div>
