import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './guards/auth-guard';
import {DeleteAccountPageComponent} from './modules/delete-account-page/delete-account-page.component';


const routes: Routes = [
  { path: '',  loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'results', canActivate: [AuthGuard], loadChildren: () => import('./modules/results/results.module').then(m => m.ResultsModule) },
  { path: 'wishlist', canActivate: [AuthGuard], loadChildren: () => import('./modules/wishlist/wishlist.module').then(m => m.WishlistModule) },
  { path: 'reset-password', loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: 'delete-account', component: DeleteAccountPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
