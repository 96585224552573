import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, share} from 'rxjs/operators';
import {WishlistStorageService} from '@shared/storage/wishlist.storage.service';
import {WishlistItem} from '@shared/models/wishlist-item.model';
import {BleenResultItem} from '@bleen/ui-core';
import {getAttributeValue} from '@shared/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class WishlistStore {
  private wishlistProductsSource: BehaviorSubject<WishlistItem[]> = new BehaviorSubject([]);

  constructor(private wishlistStorageService: WishlistStorageService) {
    if (this.wishlistStorageService.getWishlist().length > 0) {
      this.setProducts(this.wishlistStorageService.getWishlist());
    }
  }

  get products(): Observable<WishlistItem[]> {
    return this.wishlistProductsSource.asObservable()
      .pipe(
        distinctUntilChanged(),
        share()
      );
  }

  get productsCurrentValue() {
    return this.wishlistProductsSource.getValue();
  }

  setProducts(products: WishlistItem[]): void {
    this.wishlistStorageService.setWishlist(products);
    this.wishlistProductsSource.next(products);
  }

  addProduct(bleenResultItem: BleenResultItem) {
    const currentValue = this.wishlistProductsSource.getValue();
    const wishlistItem = this.bleenResultToWishlistItem(bleenResultItem);

    const productIndex = currentValue.findIndex( p => p.productCode === wishlistItem.productCode );
    let updatedValue: WishlistItem[];
    if (productIndex > -1) {
      updatedValue = [...currentValue];
      updatedValue[productIndex].qta += 1;
    } else {
      wishlistItem.qta = 1;
      updatedValue = [...currentValue, wishlistItem];
    }
    this.wishlistStorageService.setWishlist(updatedValue);
    this.wishlistProductsSource.next(updatedValue);
  }

  removeProduct(productCode: string) {
    const currentValue = this.wishlistProductsSource.getValue();
    const productIndex = currentValue.findIndex( p => p.productCode === productCode );
    if (productIndex > -1) {
      const updatedValue = [...currentValue];
      updatedValue.splice(productIndex, 1);
      this.wishlistStorageService.setWishlist(updatedValue);
      this.wishlistProductsSource.next(updatedValue);
    }
  }

  addUnityProduct(productCode: string) {
    let newCountValue;
    const currentValue = this.wishlistProductsSource.getValue();
    const productIndex = currentValue.findIndex( p => p.productCode === productCode );
    if (productIndex > -1) {
      const updatedValue = [...currentValue];
      updatedValue[productIndex].qta += 1;
      newCountValue = updatedValue[productIndex].qta;
      this.wishlistStorageService.setWishlist(updatedValue);
      this.wishlistProductsSource.next(updatedValue);
    }

    return newCountValue;
  }

  removeUnityProduct(productCode: string) {
    let newCountValue;
    const currentValue = this.wishlistProductsSource.getValue();
    const productIndex = currentValue.findIndex( p => p.productCode === productCode );
    if (productIndex > -1) {
      const updatedValue = [...currentValue];
      updatedValue[productIndex].qta -= 1;
      newCountValue = updatedValue[productIndex].qta;
      this.wishlistStorageService.setWishlist(updatedValue);
      this.wishlistProductsSource.next(updatedValue);
    }

    return newCountValue;
  }

  clear(): void {
    this.wishlistStorageService.clear();
    this.wishlistProductsSource.next([]);
  }

  private bleenResultToWishlistItem(bleenResultItem: BleenResultItem): WishlistItem {
    const product: BleenResultItem = JSON.parse(JSON.stringify(bleenResultItem));

    const productCode: string = getAttributeValue(
      product.attributes,
      'prod_code'
    );
    const productImage: string = getAttributeValue(
      product.attributes,
      'prodotto_immagini'
    );

    const productPrice: string = getAttributeValue(
      product.attributes,
      'prezzo'
    );

    return {
      id: null,
      wishlist: null,
      productName: bleenResultItem.title,
      productEntityId: bleenResultItem.id,
      productCode: productCode,
      productImage: productImage,
      productPrice: productPrice
    }
  }

}
