import { Injectable } from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {Platform} from '@angular/cdk/platform';
import {timer} from 'rxjs';
import {take} from 'rxjs/operators';
import {PwaPromptComponent} from '@shared/components';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private promptEvent: any;

  constructor(
    private swUpdate: SwUpdate,
    private bottomSheet: MatBottomSheet,
    private platform: Platform
  ) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if(confirm("Nuova versione disponibile. Vuoi caricarla?")) {
          window.location.reload();
        }
      });
    }
  }

  public initPwaPrompt() {
    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.promptEvent = event;
        this.openPromptComponent('android');
      });
    }
    if (this.platform.IOS) {
      const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
      if (!isInStandaloneMode) {
        this.openPromptComponent('ios');
      }
    }
  }

  private openPromptComponent(mobileType: 'ios' | 'android') {
    timer(1500)
      .pipe(take(1))
      .subscribe(() => this.bottomSheet.open(PwaPromptComponent, { panelClass: 'pwa-prompt', data: { mobileType, promptEvent: this.promptEvent } }));
  }
}
