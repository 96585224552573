import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '@env';
import {share} from 'rxjs/operators';
import {WishlistUserModel, WishlistUserRegistrationModel} from '@shared/models/wishlist-user.model';
import {BleenResultItem} from '@bleen/ui-core';
import {WishlistService} from './wishlist.service';
import {AgencyContactInfo} from '@shared/models/agency-contact-info.model';

@Injectable({
  providedIn: 'root',
})
export class WishlistAuthService {
  lastItemAdded: BleenResultItem;

  private apiUrl = environment.wishlistApiUrl;

  private agenciesContactInfoSubject: BehaviorSubject<AgencyContactInfo[]> = new BehaviorSubject<AgencyContactInfo[]>(null);
  private userLoggedSubject: BehaviorSubject<boolean> = new BehaviorSubject(localStorage.getItem('tokenAuthorization') != null && localStorage.getItem('userVerified') === 'true');
  private userInfoSubject: BehaviorSubject<WishlistUserModel> = new BehaviorSubject(localStorage.getItem('userInfo') != null ? JSON.parse(localStorage.getItem('userInfo')) : null);

  constructor(
      private http: HttpClient,
      private wishlistService: WishlistService
  ) {}

  get userLogged(): Observable<boolean> {
    return this.userLoggedSubject.asObservable().pipe(share());
  }
  get userLoggedInfo(): Observable<WishlistUserModel> {
    return this.userInfoSubject.asObservable().pipe(share());
  }

  get currentUserLoggedStatus() {
    return this.userLoggedSubject.getValue();
  }

  get agenciesContactInfo() {
    return this.agenciesContactInfoSubject.asObservable().pipe(share());
  }

  get isUserLogged(): boolean {
    return this.userLoggedSubject.getValue();
  }

  changeLoginStatus(status) {
    this.userLoggedSubject.next(status);
  }

  changeUserInfo(userInfo: WishlistUserModel) {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    this.userInfoSubject.next(userInfo);
  }

  login(loginRequest: {password: string, username: string}): Observable<any>{
    const headers = new HttpHeaders({
      'Skip-interceptor': 'true',
    });
    return this.http.post<any>(`${this.apiUrl}/login`, loginRequest, {
      headers: headers,
      observe: 'response'
    });
  }

  logout() {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userVerified');
    localStorage.removeItem('tokenAuthorization');
    this.changeLoginStatus(false);
    this.changeUserInfo(null);
    this.wishlistService.unsubscribeFromWishlistStore();
  }

  getAndSaveAgenciesInfo() {
    this.getUserInfo().subscribe(resp => this.changeUserInfo(resp));
    this.getAgenciesInfo().subscribe(resp => this.agenciesContactInfoSubject.next(resp));
  }

  registration(registrationRequest: WishlistUserRegistrationModel): Observable<any>{
    return this.http.post<any>(`${this.apiUrl}/auth/register`, registrationRequest);
  }

  getUserInfo(): Observable<WishlistUserModel>{
    return this.http.get<WishlistUserModel>(`${this.apiUrl}/user-info`);
  }

  getAgenciesInfo(): Observable<AgencyContactInfo[]>{
    return this.http.get<AgencyContactInfo[]>(`${this.apiUrl}/agency-contact-info`);
  }

  verifyCode(userVerifyRequest: {email: string, verificationCode: string}): Observable<any>{
    return this.http.post<any>(`${this.apiUrl}/auth/verify`, userVerifyRequest, {
      observe: 'response'
    });
  }

  resendCode(sendUserCodeRequest: {email: string}): Observable<any>{
    return this.http.post<any>(`${this.apiUrl}/auth/resend-code`, sendUserCodeRequest);
  }

  resetPassword(resetPasswordRequest: {email: string}): Observable<any>{
    return this.http.post<any>(`${this.apiUrl}/auth/reset-password`, resetPasswordRequest);
  }

  completeResetPassword(completeResetPasswordRequest: {email: string}): Observable<any>{
    return this.http.post<any>(`${this.apiUrl}/auth/complete-reset-password`, completeResetPasswordRequest);
  }

  deleteAccount(deleteAccountRequest: {email: string}): Observable<boolean>{
    return this.http.post<boolean>(`${this.apiUrl}/delete-account`, deleteAccountRequest);
  }

  deleteAccountConfirmation(token: string): Observable<boolean>{
    return this.http.get<boolean>(`${this.apiUrl}/auth/confirm-delete-account/${token}`);
  }
}
