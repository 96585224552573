<h4 mat-dialog-title>
  {{title}}
</h4>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<div mat-dialog-actions>
  <button class="storm-button dark" (click)="onDismiss()">NO</button>
  <button class="storm-button" (click)="onConfirm()">SI</button>
</div>
