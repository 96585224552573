<div class="d-flex flex-column h-100">
  <div class="close_modal">
    <button (click)="onClose()">
      <fa-icon icon="times"></fa-icon>
    </button>
  </div>
  <div class="flex-fill px-4 pb-4 modal-authentication">
    <app-login-screen
      *ngIf="loginScreen"
      (loginPress)="onLoginPress($event)"
    ></app-login-screen>
    <app-lost-password-screen
      *ngIf="lostPasswordScreen"
      [username]="forgotPasswordUsername"
      (lostPasswordPress)="onLostPasswordPress($event)"
    ></app-lost-password-screen>
    <app-registration-screen
      *ngIf="registrationScreen"
      (registrationPress)="onRegistrationPress($event)"
    ></app-registration-screen>
    <app-confirm-registration-screen
      *ngIf="confirmRegistrationScreen"
      (confirmedRegistrationPress)="onConfirmRegistrationPress($event)"
    ></app-confirm-registration-screen>
    <app-confirm-email-screen
      *ngIf="confirmEmail"
      (emailVerify)="onEmailVerify($event)"
    ></app-confirm-email-screen>
  </div>
</div>
