import {Component, OnDestroy, OnInit} from '@angular/core';
import {SubSink} from 'subsink';
import {WishlistAuthService} from '../../../modules/wishlist/services/wishlist-auth.service';
import {WishlistStore} from '@shared/store/wishlist.store';
import {CurrentWishlist} from '@shared/models/current-wishlist.model';
import {WishlistService} from '../../../modules/wishlist/services/wishlist.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-wishlist-cart',
  templateUrl: './wishlist-cart.component.html',
  styleUrls: ['./wishlist-cart.component.scss']
})
export class WishlistCartComponent implements OnInit, OnDestroy {
  userLogged = false;
  wishlistProductsCount: number;

  private currentWishlist: CurrentWishlist;

  private subs = new SubSink();

  constructor(
    private router: Router,
    private wishlistStore: WishlistStore,
    private wishlistService: WishlistService,
    private wishlistAuthService: WishlistAuthService
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.wishlistAuthService.userLogged.subscribe((userLog) => {
      this.userLogged = userLog;
    });
    this.subs.sink = this.wishlistService.currentWishlistObservable.subscribe(wishlist => {
      this.currentWishlist = wishlist;
    });

    this.subs.sink = this.wishlistStore.products.subscribe((results) => {
      this.wishlistProductsCount =
        results.reduce((acc, curr) => {
          return (acc += curr.qta);
        }, 0) || 0;
    });
  }

  redirectToWishlist(event: MouseEvent) {
    event.preventDefault();
    if (this.currentWishlist) {
      this.router.navigate(['wishlist', 'detail', this.currentWishlist.code])
    } else {
      alert('Si è verificato un errore. Prova a ricaricare la pagina o contatta l\'amministratore');
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
