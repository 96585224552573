import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WishlistAuthService} from '../../../../../modules/wishlist/services/wishlist-auth.service';
import {SubSink} from 'subsink';

@Component({
  selector: 'app-registration-screen',
  templateUrl: './registration-screen.component.html',
  styleUrls: ['./registration-screen.component.scss']
})
export class RegistrationScreenComponent implements OnInit, OnDestroy {
  @Output() registrationPress: EventEmitter<string> = new EventEmitter<string>();

  isLoading: boolean;

  isSubmittedForm = false;
  registrationError: string;

  userRegistrationForm: FormGroup;
  companyRegistrationForm: FormGroup;


  private subs = new SubSink();

  constructor(
    private formBuilder: FormBuilder,
    private wishlistAuthService: WishlistAuthService
  ) {
    this.userRegistrationForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', Validators.required],
      phone: [''],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')
        ]
      ],
      newsletter: [false],
      privacyAccepted: [false, Validators.requiredTrue]
    });
    this.companyRegistrationForm = this.formBuilder.group({
      ragioneSociale: ['', Validators.required],
      partitaIva: ['', Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', Validators.required],
      phone: [''],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')
        ]
      ],
      newsletter: [false],
      privacyAccepted: [false, Validators.requiredTrue]
    });
  }

  ngOnInit(): void {
  }

  get usersPrivacyAccepted() { return this.userRegistrationForm.value.privacyAccepted; }
  get companyPrivacyAccepted() { return this.companyRegistrationForm.value.privacyAccepted; }

  doRegistration() {
    this.registrationError = null;
    this.isSubmittedForm = true;

    this.isLoading = true;

    if (this.userRegistrationForm.invalid) {
      this.isLoading = false;
      return;
    }

    const request = {
      ...this.userRegistrationForm.value,
      isCompany: false
    };
    this.subs.sink = this.wishlistAuthService.registration(request).subscribe(resp => {
      this.wishlistAuthService.changeUserInfo(resp);

      this.registrationPress.emit('userRegistration');
      this.isLoading = false;
    }, (errorObj) => {
      this.registrationError = errorObj.error;
      this.isLoading = false;
    });
  }

  doCompanyRegistration() {
    this.registrationError = null;
    this.isSubmittedForm = true;

    this.isLoading = true;

    if (this.companyRegistrationForm.invalid) {
      this.isLoading = false;
      return;
    }

    const request = {
      ...this.companyRegistrationForm.value,
      isCompany: true
    };
    this.subs.sink = this.wishlistAuthService.registration(request).subscribe(resp => {
      this.wishlistAuthService.changeUserInfo(resp);

      this.registrationPress.emit('companyRegistration');
      this.isLoading = false;
    }, (errorObj) => {
      this.registrationError = errorObj.error;
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
