<ngx-loading [show]="isLoading === true"></ngx-loading>
<div class="registration-box d-flex flex-column justify-content-between">
  <mat-tab-group
    class="w-100 h-100 single_tab_registration"
    mat-stretch-tabs
  >
    <mat-tab label="Privato">
      <form
        class="h-100 d-flex flex-column justify-content-between"
        [ngClass]="isSubmittedForm ? 'sumbitted' : ''"
        [formGroup]="userRegistrationForm">
        <div class="mt-4 mb-4 registration-form">
          <span class="label-registration">Nome *</span>
          <input
            class="w-100 form-control"
            formControlName="name"
          />
          <span class="label-registration">Cognome *</span>
          <input
            class="w-100 form-control"
            formControlName="surname"
          />
          <span class="label-registration">Email *</span>
          <input
            type="email"
            class="w-100 form-control"
            formControlName="email"
          />
          <span class="label-registration">Telefono</span>
          <input
            class="w-100 form-control"
            formControlName="phone"
          />
          <span class="label-registration">Password *</span>
          <input
            class="w-100 form-control"
            type="password"
            formControlName="password"
          />
          <small class="form-text text-muted">La password deve contenere almeno 8 caratteri, una lettera maiuscola, una
            lettera minuscola, un numero e un carattere speciale (!, ?, $, #, %)</small>

          <div class="form-group mt-3" id="privacy-wrapper">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="newsletter" formControlName="newsletter">
              <label class="form-check-label checkbox-label" for="newsletter">Desidero ricevere aggiornamenti dal mondo STORM.</label>
            </div>

            <div class="form-check mt-1">
              <input type="checkbox" class="form-check-input" id="privacyAccepted" formControlName="privacyAccepted">
              <label class="form-check-label checkbox-label" for="privacyAccepted" [ngClass]="{'text-danger': isSubmittedForm && !usersPrivacyAccepted}">
                Ho preso visione dell'informativa sulla privacy ai sensi del Reg.to UE 2016/679 - GDPR e autorizzo il trattamento dei miei dati.
                <small *ngIf="isSubmittedForm && !usersPrivacyAccepted" class="d-block text-danger font-weight-bold pt-2">
                  È necessario accettare l'informativa sulla privacy
                </small>
              </label>
            </div>
          </div>

          <div class="mt-2 p-2 w-100 text-center alert alert-danger" *ngIf="registrationError">{{registrationError}}</div>
          <span *ngIf="userRegistrationForm.errors">{{userRegistrationForm.errors | json}}</span>
        </div>
        <div>
          <div class="col-12" (click)="doRegistration()">
            <button type="submit" class="storm-button storm_primary w-100">Registrati</button>
          </div>
        </div>
      </form>
    </mat-tab>
    <mat-tab label="Aziende">
      <form
        class="h-100 d-flex flex-column justify-content-between"
        [ngClass]="isSubmittedForm ? 'sumbitted' : ''"
        [formGroup]="companyRegistrationForm">
        <div class="mt-4 mb-4 registration-form">
          <span class="label-registration">Ragione Sociale *</span>
          <input
            class="w-100 form-control"
            formControlName="ragioneSociale"
          />
          <span class="label-registration">Partita Iva *</span>
          <input
            class="w-100 form-control"
            formControlName="partitaIva"
          />
          <span class="label-registration">Nome *</span>
          <input
            class="w-100 form-control"
            formControlName="name"
          />
          <span class="label-registration">Cognome *</span>
          <input
            class="w-100 form-control"
            formControlName="surname"
          />
          <span class="label-registration">Email *</span>
          <input
            class="w-100 form-control"
            formControlName="email"
            type="email"
          />
          <span class="label-registration">Telefono</span>
          <input
            class="w-100 form-control"
            formControlName="phone"
          />
          <span class="label-registration">Password *</span>
          <input
            class="w-100 form-control"
            type="password"
            formControlName="password"
          />
          <small class="form-text text-muted">La password deve contenere almeno 8 caratteri, una lettera maiuscola, una
            lettera minuscola, un numero e un carattere speciale (!, ?, $, #, %)</small>

          <div class="form-group mt-3" id="privacy-wrapper">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="newsletterCompany" formControlName="newsletter">
              <label class="form-check-label checkbox-label" for="newsletterCompany">Desidero ricevere aggiornamenti dal mondo STORM.</label>
            </div>

            <div class="form-check mt-1">
              <input type="checkbox" class="form-check-input" id="privacyAcceptedCompany" formControlName="privacyAccepted">
              <label class="form-check-label checkbox-label" for="privacyAcceptedCompany" [ngClass]="{'text-danger': isSubmittedForm && !companyPrivacyAccepted}">
                Ho preso visione dell'informativa sulla privacy ai sensi del Reg.to UE 2016/679 - GDPR e autorizzo il trattamento dei miei dati.
                <small *ngIf="isSubmittedForm && !companyPrivacyAccepted" class="d-block text-danger font-weight-bold pt-2">
                  È necessario accettare l'informativa sulla privacy
                </small>
              </label>
            </div>
          </div>

          <div class="mt-2 p-2 w-100 text-center alert alert-danger" *ngIf="registrationError">{{registrationError}}</div>
        </div>
        <div>
          <div class="col-12" (click)="doCompanyRegistration()">
            <button type="submit" class="storm-button storm_primary w-100">Registrati</button>
          </div>
        </div>
      </form>
    </mat-tab>
  </mat-tab-group>
</div>

