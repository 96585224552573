import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from '@core/core.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {BleenCssVarsPolyfillModule, BleenLayoutProviderService} from '@bleen/ui-core';

import {environment} from '@env';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from '@shared/interceptors/auth.interceptor';
import {AuthGuard} from './guards/auth-guard';
import {DeleteAccountPageComponent} from './modules/delete-account-page/delete-account-page.component';
import {NgxLoadingModule} from 'ngx-loading';
import {ServiceWorkerModule} from '@angular/service-worker';
import {PwaService} from '@shared/services/pwa.service';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {ConfigService} from '@core/services/config.service';

const layoutInitializer = (layoutProvider: BleenLayoutProviderService) => () => layoutProvider.load();
const configInitializer = (configService: ConfigService) => () => configService.loadConfigurations();
const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();

@NgModule({
  declarations: [
    AppComponent,
    DeleteAccountPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule,
    BleenCssVarsPolyfillModule,
    NgxLoadingModule,
    MatBottomSheetModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
  ],
  providers: [
    AuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: layoutInitializer,
      deps: [BleenLayoutProviderService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configInitializer,
      deps: [ConfigService],
      multi: true
    },
    {provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true},
    {provide: 'environment', useValue: environment},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
