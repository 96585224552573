import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WishlistAuthService} from '../../../../../modules/wishlist/services/wishlist-auth.service';
import {WishlistStore} from '@shared/store/wishlist.store';
import {ToastrService} from 'ngx-toastr';
import {WishlistService} from '../../../../../modules/wishlist/services/wishlist.service';
import {SubSink} from 'subsink';

export type LoginEvent = { action: string, data?: any };

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss']
})
export class LoginScreenComponent implements OnInit, OnDestroy {

  @Output() loginPress: EventEmitter<LoginEvent> = new EventEmitter<LoginEvent>();

  isLoading: boolean;

  isSubmittedForm = false;
  invalidCredentials = false;

  loginForm: FormGroup;

  private subs = new SubSink();

  constructor(
    private formBuilder: FormBuilder,
    private wishlistStore: WishlistStore,
    private wishlistAuthService: WishlistAuthService,
    private wishlistService: WishlistService,
    private toastr: ToastrService,
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  doLogin() {
    this.isLoading = true;
    this.isSubmittedForm = true;
    this.invalidCredentials = false;

    if (this.loginForm.invalid){
      this.isLoading = false;
      return;
    }

    this.subs.sink = this.wishlistAuthService.login(this.loginForm.value).subscribe(resp => {
      localStorage.setItem('tokenAuthorization', resp.headers.get('Authorization'));

      this.subs.sink = this.wishlistAuthService.getUserInfo().subscribe(userInfo => {
        this.wishlistAuthService.changeUserInfo(userInfo);

        if (userInfo.verified){
          this.wishlistAuthService.changeLoginStatus(true);
          localStorage.setItem('userVerified', 'true');

          this.wishlistAuthService.getAndSaveAgenciesInfo();
          this.loadCurrentWishlist(() => {
            if (this.wishlistAuthService.lastItemAdded) {
              this.wishlistAuthService.lastItemAdded.highlights = [];
              this.wishlistStore.addProduct(this.wishlistAuthService.lastItemAdded);
              this.toastr.success('Prodotto aggiunto alla wishlist');
            }
            this.loginPress.emit({action: 'login'});
          });
        } else {
          this.loginPress.emit({action: 'verifyCode'});
        }

        this.isLoading = false;
      }, (err) => {
        this.isLoading = false;
      });
    }, (err) => {
      this.invalidCredentials = true;
      this.isLoading = false;
    });
  }

  doLostPassword() {
    this.loginPress.emit({action: 'lostPassword', data: this.loginForm.value.username});
  }

  doRegistration() {
    this.loginPress.emit({action: 'registration'});
  }

  private loadCurrentWishlist(callback?) {
    this.subs.sink = this.wishlistService.getCurrentWishlist()
      .subscribe(resp => {
        if (resp.data) {
          this.wishlistService.currentWishlist = resp.data;
          this.wishlistService.setCurrentWishlist(resp.data);
          this.wishlistStore.setProducts(resp.data.items);
        } else {
          this.wishlistStore.setProducts([]);
        }

        if (callback) {
          callback();
        }
        this.wishlistService.listenToStorageWishlistChanges();
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
