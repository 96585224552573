import { Injectable } from '@angular/core';
import {WishlistItem} from '@shared/models/wishlist-item.model';

@Injectable({
  providedIn: 'root',
})
export class WishlistStorageService {
  private readonly storageKey = 'wishlist';

  setWishlist(products: WishlistItem[]): void {
    localStorage.setItem(this.storageKey, JSON.stringify(products));
  }

  getWishlist(): WishlistItem[] {
    const storageValue = localStorage.getItem(this.storageKey);
    return storageValue ? JSON.parse(storageValue) : [];
  }

  clear(): void {
    localStorage.removeItem(this.storageKey);
  }
}
