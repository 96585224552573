import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-confirm-registration-screen',
  templateUrl: './confirm-registration-screen.component.html',
  styleUrls: ['./confirm-registration-screen.component.scss']
})
export class ConfirmRegistrationScreenComponent implements OnInit {

  @Output() confirmedRegistrationPress: EventEmitter<string> = new EventEmitter<string>();

  constructor(
  ) {}

  ngOnInit(): void {
  }

  doConfirmRegistration() {
    this.confirmedRegistrationPress.emit('confirmedRegistration');
  }

}
