import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {LoginEvent} from '@shared/components';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {

  lostPasswordScreen = false;
  loginScreen = true;
  registrationScreen = false;
  confirmRegistrationScreen = false;
  confirmEmail = false;
  forgotPasswordUsername: string;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<AuthenticationComponent>
  ) {
    this.dialogRef.addPanelClass('authentication-overlay');
  }

  ngOnInit(): void {
    this.setDialogDefaultSize();
  }

  onClose(): void {
    this.router.navigate([], {
      queryParams: {
        e: null,
      },
      queryParamsHandling: 'merge',
    });
    this.dialogRef.close();
  }

  onLoginPress(event: LoginEvent) {
    switch (event.action) {
      case 'login':
        this.dialogRef.close();
        break;
      case 'verifyCode':
        this.loginScreen = false;
        this.confirmEmail = true;
        break;
      case 'lostPassword':
        this.forgotPasswordUsername = event.data;
        this.loginScreen = false;
        this.lostPasswordScreen = true;
        break;
      case 'registration':
        this.loginScreen = false;
        this.registrationScreen = true;
        break;
    }
  }


  onRegistrationPress(action){
    if (action === 'userRegistration' || action === 'companyRegistration'){
      this.registrationScreen = false;
      this.confirmRegistrationScreen = true;
    }
  }


  onConfirmRegistrationPress(action){
    if (action === 'confirmedRegistration'){
      this.confirmRegistrationScreen = false;
      this.confirmEmail = true;
    }
  }


  onLostPasswordPress(action){
    if (action === 'returnLogin'){
      this.lostPasswordScreen = false;
      this.loginScreen = true;
    }
  }

  onEmailVerify(action){
    if (action === 'verifyCode'){
      this.confirmEmail = false;
      this.dialogRef.close();
    }
  }

  private setDialogDefaultSize() {
    this.dialogRef.updateSize('50%', '90vh');
  }

}
