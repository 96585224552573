import {Component, OnInit} from '@angular/core';
import { WishlistService } from './modules/wishlist/services/wishlist.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {WishlistStore} from '@shared/store/wishlist.store';
import {CurrentWishlist} from '@shared/models/current-wishlist.model';
import {WishlistAuthService} from './modules/wishlist/services/wishlist-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  currentWishlistLoaded: boolean;

  constructor(
    private ngxService: NgxUiLoaderService,
    private wishlistService: WishlistService,
    private wishlistStore: WishlistStore,
    private wishlistAuthService: WishlistAuthService
  ) {}

  ngOnInit() {
    if (this.wishlistAuthService.currentUserLoggedStatus === true) {
      this.loadCurrentWishlist();
      this.wishlistAuthService.getAndSaveAgenciesInfo();
    } else {
      this.currentWishlistLoaded = true;
    }
  }

  private loadCurrentWishlist() {
    this.ngxService.start();
    this.wishlistService.getCurrentWishlist()
      .subscribe(resp => {
        this.currentWishlistLoaded = true;
        this.ngxService.stop();
        if (resp.data) {
          this.setCurrentWishlist(resp.data);
          this.wishlistStore.setProducts(resp.data.items);
        } else {
          this.wishlistStore.setProducts([]);
        }

        this.wishlistService.listenToStorageWishlistChanges();
      }, () => {
        this.ngxService.stop();
        this.currentWishlistLoaded = true;
      });
  }

  private setCurrentWishlist(currentWishlist: CurrentWishlist) {
    this.wishlistService.currentWishlist = currentWishlist;
    this.wishlistService.setCurrentWishlist(currentWishlist);
  }

}
