import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LayoutDefinition} from '@shared/models/template.model';
import {SubSink} from 'subsink';
import {BleenComponentItemClass, BleenSearchStore} from '@bleen/ui-core';
import {LayoutService, WidgetAreas} from '@shared/services/layout/layout.service';
import {Router} from '@angular/router';
import {DettaglioRisultatoComponent} from '@shared/components/dettaglio-risultato/dettaglio-risultato.component';
import {debounceTime} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-mobile-filter',
  templateUrl: './mobile-filter.component.html',
  styleUrls: ['./mobile-filter.component.scss'],
})
export class MobileFilterComponent implements OnInit, OnDestroy {
  layoutDef: LayoutDefinition;
  WidgetAreas = WidgetAreas;

  leftAreaWidgets: BleenComponentItemClass[] = [];
  rightAreaWidgets: BleenComponentItemClass[] = [];

  resultsCount: number;
  showResultsCount = false;
  skipFirstResultsSubscribe = true;

  filteredRightAreaWidgets: BleenComponentItemClass[] = [];
  widgetFilterValue: string;
  noWidgets: boolean;

  private widgetFilterSubject: Subject<string> = new Subject<string>();

  private subs = new SubSink();

  constructor(
    private router: Router,
    private searchStore: BleenSearchStore,
    private layoutService: LayoutService,
    public dialogRef: MatDialogRef<DettaglioRisultatoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      layoutDefinition: LayoutDefinition;
    }
  ) {
    this.layoutDef = this.data.layoutDefinition;
  }

  ngOnInit(): void {

    this.widgetFilterSubject.pipe(
      debounceTime(250),
    ).subscribe((searchText: string) => {
      this.filterRightWidgets(searchText);
    });
    this.subs.sink = this.searchStore.search.subscribe(search => {
      this.resultsCount = search.result_count;
      this.leftAreaWidgets = this.layoutService.getAreaComponents(search.widgets, this.WidgetAreas.LEFT).slice();
      this.rightAreaWidgets = this.layoutService.getAreaComponents(search.widgets, this.WidgetAreas.RIGHT).slice();
      this.filteredRightAreaWidgets = this.rightAreaWidgets.slice();

      if (this.skipFirstResultsSubscribe) {
        this.skipFirstResultsSubscribe = false;
        return false;
      }
      this.showResultsCount = true;
    });
  }

  onWidgetFilter(event) {
    this.widgetFilterSubject.next(event.target.value);
  }

  private filterRightWidgets(searchText: string) {
    if (searchText) {
      const searchTextLower = searchText.toLowerCase();
      this.filteredRightAreaWidgets = this.rightAreaWidgets
        .filter(w => w.data.title.toLowerCase().indexOf(searchTextLower) !== -1);
    } else {
      this.filteredRightAreaWidgets = this.rightAreaWidgets;
    }
    this.noWidgets = this.filteredRightAreaWidgets.length === 0;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onClose(): void {
    this.router.navigate([], {
      queryParams: {
        e: null,
      },
      queryParamsHandling: 'merge',
    });
    this.dialogRef.close();
  }
}
