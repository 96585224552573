import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Agency} from '@shared/models/agency.model';
import {Subject} from 'rxjs';
import {WishlistService} from '../../../modules/wishlist/services/wishlist.service';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-agency-list',
  templateUrl: './agency-list.component.html',
  styleUrls: ['./agency-list.component.scss']
})
export class AgencyListComponent implements OnInit {
  @Output() agencySelected: EventEmitter<Agency> = new EventEmitter<Agency>();

  agenciesLoading: boolean;
  agencies: Agency[] = [];
  filteredAgencies: Agency[] = [];
  selectedAgency: Agency;

  filterText = '';
  filterSubject: Subject<string> = new Subject<string>();

  constructor(
    private wishlistService: WishlistService
  ) {
    this.filterSubject.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      filter(value => value.length !== 1)
    ).subscribe(value => {
      this.filterAgencies(value);
    });
  }

  ngOnInit(): void {
    this.loadAgencies();
  }

  trackByAgencyId(index: number, item: Agency) {
    return item.id;
  }

  onFiltered(value) {
    this.filterSubject.next(value);
  }

  selectAgency(agency) {
    this.selectedAgency = agency;
    this.agencySelected.emit(agency);
  }

  private loadAgencies() {
    this.agenciesLoading = true;
    this.wishlistService.getAgencies().subscribe(resp => {
      this.agenciesLoading = false;
      if (resp.success) {
        this.agencies = resp.data;
        this.filteredAgencies = resp.data;
      }
    }, () => this.agenciesLoading = false);
  }

  private filterAgencies(text: string) {
    if (!text) {
      this.filteredAgencies = this.agencies;
      return false;
    }

    if (text.length === 2) {
      this.filteredAgencies = this.agencies.filter(item => item.state.toUpperCase() === text.toUpperCase());
    } else {
      this.filteredAgencies = this.agencies.filter(item => {
        const name = item.name.toUpperCase();
        const address = item.address.toUpperCase();
        const city = item.city.toUpperCase();
        return name.indexOf(text.toUpperCase()) !== -1 || address.indexOf(text.toUpperCase()) !== -1 || city.indexOf(text.toUpperCase()) !== -1;
      });
    }
  }

}
