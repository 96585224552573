import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {WishlistAuthService} from "../wishlist/services/wishlist-auth.service";

@Component({
  selector: 'app-delete-account-page',
  templateUrl: './delete-account-page.component.html',
  styleUrls: ['./delete-account-page.component.scss']
})
export class DeleteAccountPageComponent implements OnInit {
  isLoading: boolean;
  deleteSuccess = false;
  errorMsg: string;

  constructor(
    private route: ActivatedRoute,
    private wishlistAuthService: WishlistAuthService,
  ) {}

  ngOnInit(): void {
  }

  deleteAccount() {
    this.isLoading = true;
    this.wishlistAuthService.deleteAccountConfirmation(this.route.snapshot.queryParams.token).subscribe(resp => {
      this.isLoading = false;
      this.deleteSuccess = true;
      this.wishlistAuthService.logout();
    }, (err) => {
      this.errorMsg = err.error;
      this.isLoading = false;
    });
  }
}
