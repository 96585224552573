import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  BleenSearchStorageService,
  BleenTagsStorageService,
  BleenUrlSearchObject,
} from '@bleen/ui-core';

@Component({
  selector: 'app-navigation-link',
  templateUrl: './navigation-link.component.html',
  styleUrls: ['./navigation-link.component.scss'],
})
export class NavigationLinkComponent implements OnInit {
  @Input() linkTitle: string;
  @Input() link: string;

  constructor(
    private router: Router,
    private bleenSearchStorage: BleenSearchStorageService,
    private bleenTagsStorage: BleenTagsStorageService
  ) {}

  ngOnInit(): void {}

  goBack() {
    const searchKey = this.bleenSearchStorage.getSearchKey();
    const searchTags = this.bleenTagsStorage.getTags();
    this.router.navigate([this.link], {
      queryParams: {
        s: btoa(
          JSON.stringify({
            searchKey,
            searchTags,
          } as BleenUrlSearchObject)
        ),
      },
      queryParamsHandling: 'merge',
    });
  }
}
