<ngx-loading [show]="isLoading === true"></ngx-loading>
<div class="h-100 text-center bg-white overflow-hidden">
  <div
    class="h-100 d-flex justify-content-center align-items-center flex-column"
  >
    <img src="/assets/images/logo.svg" height="80" alt=""/>
    <div
      *ngIf="!deleteSuccess"
      class="mt-5"
    >
      <span>Vuoi cancellare l'account?</span>
      <button
        class="storm-button danger mt-3 mr-auto ml-auto"
        (click)="deleteAccount()"
      >
        Cancella Account
      </button>
      <div class="mt-3 p-2 text-center alert alert-danger" *ngIf="errorMsg">{{errorMsg}}</div>
    </div>
    <div
      *ngIf="deleteSuccess"
      class="mt-5"
    >
      Il tuo account è stato cancellato!

      <button class="storm-button mt-4" routerLink="/">TORNA ALLA RICERCA</button>
    </div>
  </div>
</div>
