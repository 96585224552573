import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WishlistAuthService} from '../../../../../modules/wishlist/services/wishlist-auth.service';
import {WishlistService} from '../../../../../modules/wishlist/services/wishlist.service';
import {Agency} from '@shared/models/agency.model';
import {WishlistUserModel} from '@shared/models/wishlist-user.model';
import {SubSink} from 'subsink';

@Component({
  selector: 'app-confirm-email-screen',
  templateUrl: './confirm-email-screen.component.html',
  styleUrls: ['./confirm-email-screen.component.scss']
})
export class ConfirmEmailScreenComponent implements OnInit, OnDestroy {
  @Output() emailVerify: EventEmitter<string> = new EventEmitter<string>();

  isLoading: boolean;
  userInfo: WishlistUserModel;

  isSubmittedForm = false;
  codeSubmitted = false;
  wrongCode = false;
  mustSelectAgency: boolean;

  confirmationForm: FormGroup;
  selectedAgency: Agency;

  private subs = new SubSink();

  constructor(
    private formBuilder: FormBuilder,
    private wishlistAuthService: WishlistAuthService,
    private wishlistService: WishlistService
  ) {
    this.subs.sink = this.subs.sink = this.wishlistAuthService.userLoggedInfo.subscribe((userInfo) => {
      this.userInfo = userInfo;
      this.mustSelectAgency = userInfo ? userInfo.isCompany === false || userInfo.clientExists === false : false;
    });

    this.confirmationForm = this.formBuilder.group({
      verificationCode: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  doVerify() {
    if (this.mustSelectAgency === true && !this.selectedAgency) {
      return false;
    }
    this.codeSubmitted = false;
    this.wrongCode = false;

    this.isSubmittedForm = true;

    if (this.confirmationForm.invalid){
      return;
    }
    const verifyRequest = {
      email: this.userInfo.email,
      verificationCode: this.confirmationForm.value.verificationCode,
      codiceFiliale: this.selectedAgency ? this.selectedAgency.codiceFiliale : null,
      agencyPublicId: this.selectedAgency ? this.selectedAgency.publicId : null,
    };
    this.isLoading = true;
    this.subs.sink = this.wishlistAuthService.verifyCode(verifyRequest).subscribe(verificationResponse => {
      if (verificationResponse.body){
        this.emailVerify.emit('verifyCode');

        if (!localStorage.getItem('tokenAuthorization')){
          localStorage.setItem('tokenAuthorization', verificationResponse.headers.get('Authorization'));
        }

        this.wishlistAuthService.changeLoginStatus(true);
        localStorage.setItem('userVerified', 'true');
        this.wishlistService.listenToStorageWishlistChanges();
      } else {
        this.wrongCode = true;
      }
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
    });
  }

  resendVerify() {
    this.codeSubmitted = false;
    this.wrongCode = false;

    this.isLoading = true;

    this.subs.sink = this.wishlistAuthService.resendCode({email: this.userInfo.email}).subscribe(newCode => {
      this.codeSubmitted = true;
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
