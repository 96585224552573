import { Injectable } from '@angular/core';
import {environment} from '@env';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {WishlistSaveRequest} from '@shared/models/requets/wishlist-save-request.model';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {WishlistListResponse} from '@shared/models/responses/wishlist-list.response.model';
import {CurrentWishlistResponse} from '@shared/models/responses/current-wishlist.response.model';
import {WishlistCurrentItemRequest, WishlistSaveCurrentRequest} from '@shared/models/requets/wishlist-save-current-request.model';
import {CurrentWishlist} from '@shared/models/current-wishlist.model';
import {WishlistStore} from '@shared/store/wishlist.store';
import {AgenciesResponse} from '@shared/models/responses/agencies.response.model';
import {WishlistDetailResponse} from '@shared/models/responses/wishlist-detail.response.model';
import {share} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {
  private apiUrl = `${environment.wishlistApiUrl}/api`;
  private openApiUrl = `${environment.wishlistApiUrl}/open/api`;
  private downloadReportUrl = `${environment.wishlistApiUrl}/open/api`;

  currentWishlist: CurrentWishlist;
  skipNextWishlistSave: boolean;

  private currentWishlistSubject: BehaviorSubject<CurrentWishlist> = new BehaviorSubject<CurrentWishlist>(null);

  private wishlistSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private wishlistStore: WishlistStore
  ) { }

  get currentWishlistObservable(): Observable<CurrentWishlist> {
    return this.currentWishlistSubject.asObservable().pipe(share());
  }

  setCurrentWishlist(wishlist: CurrentWishlist) {
    this.currentWishlistSubject.next(wishlist);
  }

  getWishlists(): Observable<WishlistListResponse> {
    return this.http.get<WishlistListResponse>(`${this.apiUrl}/wishlist`);
  }

  getCurrentWishlist(): Observable<CurrentWishlistResponse> {
    return this.http.get<CurrentWishlistResponse>(`${this.apiUrl}/wishlist/current`);
  }

  getWishlistDetail(wishlistCode: string): Observable<WishlistDetailResponse> {
    return this.http.get<WishlistDetailResponse>(`${this.apiUrl}/wishlist/detail/${wishlistCode}`);
  }

  saveWishlist(wishlistSaveRequest: WishlistSaveRequest): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/wishlist/save`,
      wishlistSaveRequest
    );
  }

  saveCurrentWishlist(currentWishlistSaveRequest: WishlistSaveCurrentRequest): Observable<CurrentWishlistResponse> {
    return this.http.post<CurrentWishlistResponse>(
      `${this.apiUrl}/wishlist/save/current`,
      currentWishlistSaveRequest
    );
  }

  getWishlist(wishlistId: string): any {
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf; charset=utf-8',
    });
    return this.http.get(`${this.downloadReportUrl}/report/${wishlistId}`, {
      headers,
      responseType: 'blob',
    });
  }

  getAgencies(): Observable<AgenciesResponse> {
    return this.http.get<AgenciesResponse>(`${this.openApiUrl}/wishlist/agencies`);
  }

  listenToStorageWishlistChanges() {
    this.wishlistSubscription = this.wishlistStore.products.subscribe((items) => {
      if (this.skipNextWishlistSave === true) {
        this.skipNextWishlistSave = false;
        return;
      }
      const wishlistReqItems: WishlistCurrentItemRequest[] = items.map(item => ({
        code: item.productCode,
        entityId: item.productEntityId ? item.productEntityId.toString() : null,
        image: item.productImage,
        name: item.productName,
        quantity: item.qta,
        price: item.productPrice
      }));
      const req: WishlistSaveCurrentRequest = {
        wishlistCode: this.currentWishlist ? this.currentWishlist.code : null,
        items: wishlistReqItems
      };
      this.saveCurrentWishlist(req)
        .subscribe(response => {
          this.currentWishlist = response.data;
          this.setCurrentWishlist(response.data);
        });
    });
  }

  unsubscribeFromWishlistStore() {
    if (this.wishlistSubscription) {
      this.wishlistSubscription.unsubscribe();
    }
  }
}
