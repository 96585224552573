<ngx-loading [show]="isLoading === true"></ngx-loading>
<div class="confirm-email-box overflow-auto">
  <form
    class="h-100 d-flex flex-column justify-content-between"
    [ngClass]="isSubmittedForm ? 'sumbitted' : ''"
    [formGroup]="confirmationForm">
    <div class="confirm-email-form text-center pb-4">
      <b class="mb-5 text-center">Inserisci il codice che ti abbiamo inviato alla mail {{userInfo.email}}</b>
      <input
        class="w-100 text-center form-control"
        formControlName="verificationCode"
      />
      <div class="mt-2 p-2 w-100 text-center alert alert-success" *ngIf="codeSubmitted">Codice inviato con successo, controlla la tua mail.</div>
      <div class="mt-2 p-2 w-100 text-center alert alert-danger" *ngIf="wrongCode">Codice errato.</div>

      <div class="mt-3" *ngIf="mustSelectAgency === true">
        <app-agency-list
          class="confirm-email-agency-list"
          (agencySelected)="selectedAgency = $event"
        ></app-agency-list>
      </div>
    </div>
    <div>
      <div class="col-12 mb-2" (click)="doVerify()">
        <button class="storm-button storm_primary w-100" [disabled]="mustSelectAgency === true && !selectedAgency">Invia</button>
      </div>
      <div class="col-12" (click)="resendVerify()">
        <div class="storm-button storm_secondary">Mandami un altro codice</div>
      </div>
    </div>
  </form>
</div>
