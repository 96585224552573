<div class="agency-list-wrapper">
  <p class="font-weight-bold mb-1">Seleziona il negozio più vicino a te: </p>

  <div class="text-center agency-spinner" *ngIf="agenciesLoading === true">
    <fa-icon icon="spinner" [spin]="true" size="2x"></fa-icon>
  </div>

  <ng-container *ngIf="agenciesLoading !== true">
    <div class="filter-wrapper d-flex w-100 form-group">
      <input type="text" placeholder="Cerca per Società, Città, Provincia, testo..." class="form-control"
             [(ngModel)]="filterText" (ngModelChange)="onFiltered($event)">
    </div>
    <span *ngIf="filteredAgencies.length === 0" class="agency-not-found">Nessun negozio</span>
    <div class="list-group agency-list">
      <div
        class="list-group-item list-group-item-action flex-column align-items-start"
        [ngClass]="{'active': agency.id === selectedAgency?.id}"
        (click)="selectAgency(agency)"
        *ngFor="let agency of filteredAgencies; trackBy:trackByAgencyId"
      >
        <div class="d-flex align-items-center">
          <img src="https://media.clerici.eu/company/{{agency.logo}}" width="80px" height="80px" style="margin-right:10px" title="{{agency.name}}">

          <div class="item-content">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="agency-name mb-1 font-weight-bold">{{agency.name}}</h5>
            </div>
            <p class="mb-0 item-description">
              {{agency.address}} - {{agency.city}} ({{agency.state}}), {{agency.cap}}<br>
              <small>Website: <a href="{{agency.website}}" target="_blank">{{agency.website}}</a> -
                Tel: {{agency.phone ? agency.phone : '-'}}</small>
            </p>
          </div>

        </div>
      </div>
    </div>
  </ng-container>
</div>
