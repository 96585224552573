import { Component, OnInit } from '@angular/core';
import {
  BleenApiService,
  BleenBaseWidgetComponent, BleenFacet, BleenFacetsStorageService, BleenFacetsStore,
  BleenSearchStore, BleenTag,
  BleenWidgetStorageService
} from '@bleen/ui-core';
import { CircleFilterWidgetData, CircleFilterWidgetResponse } from '@shared/models/circle-filter-widget.model';

@Component({
  selector: 'app-circle-filter-widget',
  templateUrl: './circle-filter-widget.component.html',
  styleUrls: ['./circle-filter-widget.component.scss']
})
export class CircleFilterWidgetComponent extends BleenBaseWidgetComponent<CircleFilterWidgetResponse> implements OnInit {
  filters = [
    {
      value: 'd',
      imgName: 'gold-filter',
      label: 'oro',
      name: 'pallino storm',
      active: false,
      selected: false
    },
    {
      value: 'b',
      imgName: 'blue-filter',
      label: 'blu',
      name: 'pallino storm',
      active: false,
      selected: false
    },
    {
      value: 'r',
      imgName: 'red-filter',
      label: 'rosso',
      name: 'pallino storm',
      active: false,
      selected: false
    },
    {
      value: 'y',
      imgName: 'yellow-filter',
      label: 'giallo',
      name: 'pallino storm',
      active: false,
      selected: false
    },
    {
      value: 'g',
      imgName: 'green-filter',
      label: 'verde',
      name: 'pallino storm',
      active: false,
      selected: false
    }
  ];

  constructor(
    bleenApiService: BleenApiService,
    bleenFacetsStore: BleenFacetsStore,
    bleenSearchStore: BleenSearchStore,
    bleenFacetsStorageService: BleenFacetsStorageService,
    bleenOpenWidgetsStorageService: BleenWidgetStorageService
  ) {
    super(
      bleenApiService,
      bleenFacetsStore,
      bleenSearchStore,
      bleenFacetsStorageService,
      bleenOpenWidgetsStorageService
    );

    this.getWidgetData().subscribe((resp) => {
      this.filters.forEach(f => {
        const filterVal = this.getFilterValue(resp.data.data, f.value);
        if (filterVal) {
          f.label = filterVal.label;
          f.active = parseInt(filterVal.resultCount, 10) > 0;
          f.selected = filterVal.selected;
        } else {
          f.label = '';
          f.active = false;
          f.selected = false;
        }
      });
    });
  }

  ngOnInit(): void {
    this.shouldReload = true;
    this.loadWidgetData();
  }

  selectFilter(filter: CircleFilterWidgetData) {
    const selectedValue = this.getCurrentSelectedValue();
    const newTagValue: BleenFacet = {
      value: filter.value,
      name: filter.name,
      label: filter.label,
      module: this.widgetData.module,
      widgetLabel: this.widgetData.title
    };
    if (selectedValue) {
      selectedValue.value === filter.value
        ? this.removeSelectedValue(newTagValue)
        : this.replaceValue(newTagValue);
    } else {
      this.addSelectedValues([newTagValue]);
    }
  }

  private getCurrentSelectedValue() {
    return this.filters.find(f => f.selected === true);
  }

  private getFilterValue(filterValues: CircleFilterWidgetData[], value: string) {
    return filterValues.find(f => f.value === value);
  }

}
