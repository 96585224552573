<ngx-loading [show]="isLoading === true"></ngx-loading>
<div class="login-box">
  <form
    class="h-100 d-flex flex-column justify-content-between"
    [ngClass]="isSubmittedForm ? 'sumbitted' : ''"
    [formGroup]="loginForm">
    <!--<div class="logo-registration text-center">-->
      <!--<img src="/assets/images/logo.svg" alt=""/>-->
    <!--</div>-->
    <div class="text_primary">La ricerca di un prodotto richiede un account utente. <br/>
      Registrati e crea il tuo account gratuitamente.</div>
    <div class="login-form">
      <input
        class="w-100 text-center form-control"
        type="email"
        placeholder="Email"
        formControlName="username"
        required
      />
      <input
        class="w-100 text-center form-control"
        type="password"
        placeholder="Password"
        formControlName="password"
        required
      />
      <div class="w-100 text-right">
        <div class="lost-password cursor-pointer" (click)="doLostPassword()">Password dimenticata?</div>
      </div>
      <div class="mt-2 p-2 w-100 text-center alert alert-danger" *ngIf="invalidCredentials">Autenticazione fallita!</div>
    </div>
    <div>
      <div class="col-12 mb-2" (click)="doLogin()">
        <button type="submit" class="storm-button storm_primary w-100">Login</button>
      </div>
      <div class="col-12" (click)="doRegistration()">
        <div class="storm-button storm_secondary">Non sei registrato?</div>
      </div>
    </div>
  </form>
</div>
