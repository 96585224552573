<div class="confirm-registration-box d-flex flex-column justify-content-between">
  <div class="mt-4 mb-4 confirm-registration">
    <div class="mt-2 p-2 w-100 text-center">Grazie per aver completato la registrazione.</div>
  </div>
  <div>
    <div class="col-12" (click)="doConfirmRegistration()">
      <div class="storm-button storm_primary">Continua</div>
    </div>
  </div>
</div>
