<div class="close_modal">
  <button (click)="onClose()"><fa-icon icon="times"></fa-icon></button>
</div>
<span *ngIf="showResultsCount" class="results-count">
  Ricerca aggiornata: trovato <span class="font-weight-bold">{{resultsCount}}</span> risultati <br/>
  <span class="font-weight-bold">(chiudere la finestra per tornare ai risultati)</span>
</span>
<div class="filter_mobile">
  <mat-tab-group
    class="w-100 single_tab">
    <mat-tab label="Categorie" *ngIf="layoutDef.leftColumn.active === true">
      <div class="tab-categorie">
        <bleen-widget-container
          [components]="leftAreaWidgets"
        ></bleen-widget-container>
      </div>
      <!--<app-widgets-container
        [area]="WidgetAreas.LEFT"
      ></app-widgets-container>-->
    </mat-tab>
    <mat-tab label="Caratteristiche" *ngIf="layoutDef.rightColumn.active === true">
      <div class="tab-caratteristiche">
        <div class="widget-filter-wrapper mt-1 mb-3">
          <input
            type="text"
            placeholder="Cerca caratteristica..."
            class="widget-filter-input"
            [(ngModel)]="widgetFilterValue"
            (keyup)="onWidgetFilter($event)"
          >
          <fa-icon icon="filter" class="widget-filter-icon"></fa-icon>
        </div>
        <p *ngIf="noWidgets" class="font-weight-bold text-center">Nessuna caratteristica trovata</p>
        <div class="mobile-widget-container">
          <bleen-widget-container
            *ngIf="filteredRightAreaWidgets.length > 0"
            [components]="filteredRightAreaWidgets"
          ></bleen-widget-container>
        </div>
      </div>
      <!--<app-widgets-container
        [area]="WidgetAreas.RIGHT"
      ></app-widgets-container>-->
    </mat-tab>
  </mat-tab-group>
</div>

