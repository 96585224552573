import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@env';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private configurations: any = {};
  private testModeActive: boolean;

  private apiUrl = `${environment.wishlistApiUrl}`;

  constructor(
    private httpClient: HttpClient,
  ) { }

  get isTestMode() { return this.testModeActive }
  set testMode(value: boolean) { this.testModeActive = value }

  loadConfigurations(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.httpClient.get<any>(`${this.apiUrl}/open/config/all`)
        .subscribe(configs => {
          this.configurations = configs;

          const testModeConfig = configs.find(item => item.name === 'test_mode');
          this.testMode = testModeConfig ? testModeConfig.value === "true" : false;
          resolve();
        }, () => {
          this.testMode = false;
          resolve();
        });
    });
  }

  updateConfiguration(configName: string, configValue: string): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.apiUrl}/config`, {
      configName,
      configValue
    });
  }
}
