import { Injectable } from '@angular/core';

import {
  BleenComponentItemClass,
  BleenLayoutProviderService,
  BleenSearchWidget,
} from '@bleen/ui-core';

import { LayoutDefinition } from '@shared/models/template.model';

import { orderBy } from 'lodash-es';
import { ComponentMappingService } from '@shared/services/component-mapping/component-mapping.service';

export enum LayoutTypes {
  FULL_WIDTH = 'fullWidth',
  TWO_COLUMN = 'dueColonne',
  THREE_COLUMN = 'treColonne',
}

export enum WidgetTypes {
  SINGLE_CHOICE = 'single_choice',
  MULTI_CHOICE = 'multi_choice',
}

export enum WidgetAreas {
  LEFT = 1,
  MIDDLE = 2,
  RIGHT = 3,
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor(
    private bleenLayoutProvider: BleenLayoutProviderService,
    private componentMappingService: ComponentMappingService
  ) {}

  getLayoutDefinition(): LayoutDefinition {
    const layoutDef: LayoutDefinition = {
      leftColumn: {},
      rightColumn: {},
    };
    switch (this.bleenLayoutProvider.getLayout().name) {
      case LayoutTypes.TWO_COLUMN:
        layoutDef.rightColumn.active = false;

        layoutDef.leftColumn.active = true;
        layoutDef.leftColumn.class = 'col-lg-3 d-none d-lg-block';
        break;
      case LayoutTypes.THREE_COLUMN:
        layoutDef.leftColumn.active = true;
        layoutDef.leftColumn.class = 'col-lg-3 d-none d-lg-block';

        layoutDef.rightColumn.active = true;
        layoutDef.rightColumn.class = 'col-lg-3 d-none d-lg-block';
        break;
      case LayoutTypes.FULL_WIDTH:
        layoutDef.leftColumn.active = false;
        layoutDef.rightColumn.active = false;
    }

    return layoutDef;
  }

  getAreaComponents(widgets: BleenSearchWidget[], area: number) {
    const areaWidgets = this.getAreaWidgets(widgets, area);
    const components = [];
    areaWidgets.forEach((w, index) => {
      const componentClass = this.componentMappingService.getComponentClass(
        w.type
      );
      if (componentClass) {
        components.push(
          new BleenComponentItemClass(componentClass, {
            title: w.title,
            instanceId: w.instance,
            key: w.key,
            // tslint:disable-next-line:object-literal-shorthand
            index: index,
          })
        );
      }
    });

    return components;
  }

  getAreaWidgets(widgets: BleenSearchWidget[], area: number) {
    /* PER DUPPLICARE I WIDGET PER IL STRESS TEST */
    /*let clonedWIdgets = [];
    if (widgets) {
      for (let i = 0; i < 10; i++) {
        const cloned = JSON.parse(JSON.stringify(widgets));
        clonedWIdgets = [...clonedWIdgets, ...cloned];
      }
    }*/
    const widgetsFiltered = widgets
      ? widgets.filter((w) => w.area === area)
      : [];
    return orderBy(widgetsFiltered, 'order');
  }
}
